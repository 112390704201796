import React from 'react';
import {useQuery} from '@tanstack/react-query';

import api from 'src/services/api';

import {PDFDisplayProps} from './PDFDisplay.d';
import Loader from 'src/components/Loader';
import Error from 'src/components/Error';

const PDFDisplay = (props: PDFDisplayProps) => {
    const {panel} = props;

    /**
     * GET PANEL AND LOAD INTO IFRAME
     */
    const fetchPdfPanelSrc = async (): Promise<string> => {
        return await api
            .get(panel.path, {
                responseType: 'arraybuffer',
            })
            .then((res) => {
                const data = res.data;
                const blob = new Blob([data], {type: 'application/pdf'});
                return window.URL.createObjectURL(blob);
            });
    };

    const {data: pdfBlob, isLoading, isError} = useQuery<string, Error>(['pdf-panel', panel.path], fetchPdfPanelSrc);

    /**
     * RENDER COMPONENT
     */
    if (isLoading) {
        return <Loader text={'Baixando arquivo'}/>;
    }

    if (isError) {
        return <Error title={'Erro ao carregar arquivo'} subtitle={'Tente novamente mais tarde.'}/>;
    }

    return <iframe title={'pdf-panel'} width={'100%'} height={'100%'} allowFullScreen={true} src={pdfBlob}></iframe>;
};

export default PDFDisplay;
