import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

import {TypeButtonProps} from './TypeButton.d';

const TypeButton = (props: TypeButtonProps) => {
    const {onClick, icon, label} = props;

    return (
        <Card
            variant={'outlined'}
            sx={{
                width: 150,
                height: 150,
            }}
        >
            <CardActionArea
                onClick={onClick}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    gap: 1,
                }}
            >
                <Box
                    component={'img'}
                    src={icon}
                    alt={'icon'}
                    sx={{
                        height: 50,
                    }}
                />
                <Typography align={'center'} children={label}/>
            </CardActionArea>
        </Card>
    );
};

export default TypeButton;
