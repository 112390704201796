import React, { useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Addchart from '@mui/icons-material/Addchart';
import AddUserIcon from '@mui/icons-material/PersonAdd';
import PasswordIcon from '@mui/icons-material/Password';
import RemoveIcon from '@mui/icons-material/Block';

import api from 'src/services/api';

import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import CrudTableBtn from 'src/components/crud/CrudTableBtn';
import ConfirmModal from 'src/components/crud/modals/ConfirmModal/ConfirmModal';

import { User } from 'src/services/storage/user';
import { Columns } from 'src/components/crud/Crud.d';

import AddPanelsModal from 'src/pages/access/Users/AddPanelsModal';
import InviteUserModal from 'src/pages/access/Users/InviteUserModal';
import RemoveUserModal from 'src/pages/access/Users/RemoveUserModal';

export const columns: Columns<User> = [
    {
        accessorKey: 'email',
        header: 'E-mail',
    },
    {
        accessorKey: 'first_name',
        header: 'Nome',
    },
    {
        accessorKey: 'last_name',
        header: 'Sobrenome',
    },
    {
        accessorKey: 'password',
        header: 'Senha',
        hideColumn: true,
        field: {
            type: 'password',
        },
    },
    {
        accessorKey: 'password_confirm',
        header: 'Confirmação de Senha',
        hideColumn: true,
        field: {
            type: 'password',
        },
    },
];

const UsersPage = () => {
    /**
     * HANDLE CRUD PAGE REFRESH
     */
    const [refresh, setRefresh] = useState(0);

    /**
     * MANAGE PANELS MODAL
     */
    const [profilesModalOpen, setProfilesModalOpen] = useState(false);
    const [modalUserEntity, setModalUserEntity] = useState<any>(null);

    const openProfilesModal = (entity: User) => {
        setModalUserEntity(entity);
        setProfilesModalOpen(true);
    };

    /**
     * RESET PASSWORD CONFIRMATION
     */
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

    const openResetPasswordModal = (entity: User) => {
        setModalUserEntity(entity);
        setResetPasswordModalOpen(true);
    };

    const handleResetPasswordCancel = () => {
        setResetPasswordModalOpen(false);
    };

    const handleResetPasswordSubmit = async (row: any) => {
        return await api
            .post(`/api/users/password-reset/`, {
                email: row.email,
            })
            .then(() => {
                enqueueSnackbar('E-mail enviado com sucesso!', { variant: 'success' });
                setResetPasswordModalOpen(false);
            })
            .catch(() => {
                enqueueSnackbar('Erro ao enviar e-mail!', { variant: 'error' });
            });
    };

    /**
     * REMOVE FROM COMPANY MODAL
     */
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [removeModalEntity, setRemoveModalEntity] = useState<any>(null);

    const openRemoveModal = (entity: User) => {
        setRemoveModalEntity(entity);
        setRemoveModalOpen(true);
    };

    /**
     * INVITE USER MODAL
     */
    const [inviteModalOpen, setInviteModalOpen] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Acessos', 'Usuários']} />
                </Box>
            </Stack>

            <CrudAPI
                name={{
                    singular: 'Usuário',
                    plural: 'Usuários',
                }}
                endpoint={'/api/users/'}
                columns={columns}
                enableUpdate={false}
                rowActions={(entity) => {
                    return (
                        <Stack direction={'row'}>
                            <CrudTableBtn
                                entity={entity}
                                onClick={openProfilesModal}
                                tooltip={'Perfis'}
                                icon={Addchart}
                                color={'primary'}
                            />
                            <CrudTableBtn
                                entity={entity}
                                onClick={openResetPasswordModal}
                                tooltip={'Redefinir Senha'}
                                icon={PasswordIcon}
                                color={'warning'}
                            />
                            <CrudTableBtn
                                entity={entity}
                                onClick={openRemoveModal}
                                tooltip={'Remover da Empresa'}
                                icon={RemoveIcon}
                                color={'error'}
                            />
                        </Stack>
                    );
                }}
                extraCrudActions={() => (
                    <Stack justifyContent={'center'} alignItems={'center'} sx={{ h: '100%' }}>
                        <Button
                            variant={'outlined'}
                            startIcon={<AddUserIcon />}
                            onClick={() => setInviteModalOpen(true)}
                            children={'Convidar'}
                        />
                    </Stack>
                )}
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
            />

            {/** ADD PANEL'S MODAL */}
            <AddPanelsModal
                open={profilesModalOpen}
                user={modalUserEntity}
                onClose={() => {
                    setProfilesModalOpen(false);
                }}
            />

            {/** RESET PASSWORD MODAL */}
            <ConfirmModal
                // MODAL CONTROL
                open={resetPasswordModalOpen}
                entity={modalUserEntity}
                // CONTENT
                title={'Redefinir Senha'}
                message={'Deseja enviar um e-mail de redefinição de senha?'}
                btnText={'Enviar'}
                // EVENTS
                onSubmit={handleResetPasswordSubmit}
                onCancel={handleResetPasswordCancel}
            />

            {/** CONFIRM COMPANY REMOVE MODAL */}
            <RemoveUserModal
                open={removeModalOpen}
                user={removeModalEntity}
                onClose={() => {
                    setRemoveModalOpen(false);
                    setRefresh((prev) => prev + 1);
                }}
            />

            {/** INVITE USER MODAL */}
            <InviteUserModal
                open={inviteModalOpen}
                onClose={() => {
                    setInviteModalOpen(false);
                    setRefresh((prev) => prev + 1);
                }}
            />
        </>
    );
};

export default UsersPage;