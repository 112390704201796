import React from 'react';

import { PowerBIDisplayProps } from './PowerBIDisplay.d';

const PowerBIDisplay = (props: PowerBIDisplayProps) => {
    const { panel } = props;

    return (
        <iframe
            title={'bi-panel'}
            width={'100%'}
            height={'100%'}
            allowFullScreen={true}
            src={`${panel.path}&navContentPaneEnabled=false`}
        ></iframe>
    );
};

export default PowerBIDisplay;