import React from 'react';

import Box from '@mui/material/Box';

import {CenterProps} from './Center.d';

const Center = (props: CenterProps) => {
    const {children} = props;

    return (
        <Box
            flexGrow={1}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
            }}
            children={children}
        />
    );
};

export default Center;