import React, { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';

import Crud from 'src/components/crud/Crud';
import CrudHeader from 'src/components/crud/CrudHeader';
import { TData } from 'src/components/crud/Crud.d';

import { CrudPageProps } from './CrudPage.d';

const CrudPage = <T extends TData>(props: CrudPageProps<T>) => {
    const {
        // STRUCTURE
        name,
        columns,
        endpoint,

        // ACTIONS
        rowActions,
        rowDoubleClick,
        extraRowActions,
        extraCrudActions,
        getIsReadOnly,
        handleCreateUpdate,

        // API CONTROL
        formatData,
        formatPayload,

        // TABLE CONTROL
        controlledRefresh,
        setControlledRefresh,
        defaultFilters,
        defaultSorting,

        // MODAL CONTROL
        controlledModalEntity,
        setControlledModalEntity,
        controlledCreateUpdateOpen,
        setControlledCreateUpdateOpen,

        // CRUD PERMISSIONS
        enableCreate = true,
        enableUpdate = true,
        enableDelete = true,

        // VISUAL
        modalSize,
    } = props;

    /**
     * GLOBAL MODAL ENTITY
     */
    const [uncontrolledModalEntity, setUncontrolledModalEntity] = useState(null);
    const modalEntity = controlledModalEntity ? controlledModalEntity : uncontrolledModalEntity;
    const setModalEntity = controlledModalEntity ? setControlledModalEntity : setUncontrolledModalEntity;

    /**
     * CREATE/UPDATE MODAL
     */
    const [uncontrolledCreateUpdateOpen, setUncontrolledCreateUpdateOpen] = useState(false);
    const createUpdateOpen = controlledCreateUpdateOpen ? controlledCreateUpdateOpen : uncontrolledCreateUpdateOpen;
    const setCreateUpdateOpen = controlledCreateUpdateOpen
        ? setControlledCreateUpdateOpen
        : setUncontrolledCreateUpdateOpen;

    const openCreateUpdateModal = (entity: T | null) => {
        if (handleCreateUpdate) {
            handleCreateUpdate(entity);
            return;
        }
        setModalEntity(entity);
        setCreateUpdateOpen(true);
    };

    /**
     * COMPONENT RENDER
     */
    return (
        <Stack direction={'column'} flexGrow={1}>
            <CrudHeader
                // STRUCTURE
                name={name}
                // ACTIONS
                extraCrudActions={extraCrudActions}
                handleCreateUpdate={openCreateUpdateModal}
                // PERMISSIONS
                enableCreate={enableCreate}
            />

            <Crud
                // STRUCTURE
                name={name}
                columns={columns}
                endpoint={endpoint}
                // ACTIONS
                rowActions={rowActions}
                extraRowActions={extraRowActions}
                handleCreateUpdate={openCreateUpdateModal}
                rowDoubleClick={rowDoubleClick}
                getIsReadOnly={getIsReadOnly}
                // MODULES
                enableCreate={enableCreate}
                enableUpdate={enableUpdate}
                enableDelete={enableDelete}
                // API CONTROL
                formatData={formatData}
                formatPayload={formatPayload}
                // TABLE CONTROL
                defaultFilters={defaultFilters}
                defaultSorting={defaultSorting}
                // MODAL CONTROL
                modalEntity={modalEntity}
                setModalEntity={setModalEntity}
                createUpdateOpen={createUpdateOpen}
                setCreateUpdateOpen={setCreateUpdateOpen}
                controlledRefresh={controlledRefresh}
                setControlledRefresh={setControlledRefresh}
                // VISUAL
                modalSize={modalSize}
            />
        </Stack>
    );
};

export default CrudPage;
