import React from 'react';

import { enqueueSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import api from 'src/services/api';
import ConfirmModal from 'src/components/crud/modals/ConfirmModal';

import { RemoveUserModalProps } from './RemoveUserModal.d';

const RemoveUserModal = (props: RemoveUserModalProps) => {
    const { open, onClose, user } = props;

    /**
     * SUBMIT CONTROL
     */
    const submitRemove = async () => {
        return await api.delete(`/api/users/${user.id}/company/`);
    };

    const { mutateAsync } = useMutation(submitRemove, {
        onError: () => enqueueSnackbar('Erro ao remover!', { variant: 'error' }),
        onSuccess: () => {
            enqueueSnackbar('Usuário removido com sucesso!', { variant: 'success' });
            onClose();
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <ConfirmModal
            // MODAL CONTROL
            open={open}
            entity={user}
            // CONTENT
            title={'Remover Usuário'}
            message={'Deseja remover o usuário da empresa?'}
            warningMessage={'O usuário será removido da empresa e não poderá mais acessar o sistema.'}
            btnText={'Remover'}
            // EVENTS
            onSubmit={async () => {
                await mutateAsync();
            }}
            onCancel={onClose}
        />
    );
};

export default RemoveUserModal;
