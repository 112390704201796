/**
 * STORE COMPANIES INFORMATION ON LOCALSTORAGE
 */

/**
 * Identifier for the current pbi account data on localstorage
 * @type {string}
 */
export const CUR_ACCOUNT_KEY = '@predicthub-current-account';
export const ACCOUNT_CHANGED_KEY = '@predicthub-account-changed';

/**
 * Set current pbi account in localstorage
 * @param {string} account - The account to be stored in localstorage
 */
export const setCurrentAccount = (account: string | null) => {
    setAccountChanged(getCurrentAccount() !== account || account === null);
    localStorage.setItem(CUR_ACCOUNT_KEY, account ?? '');
};

/**
 * Get company visual data from localstorage
 * @returns {object | null} - Returns the company visual data if present in localstorage, null otherwise
 */
export const getCurrentAccount = () => {
    return localStorage.getItem(CUR_ACCOUNT_KEY) ?? null;
};

/**
 * Set boolean to indicate if account was changed
 * @param {boolean} changed - The account to be stored in localstorage
 */
export const setAccountChanged = (changed: boolean) => {
    localStorage.setItem(
        ACCOUNT_CHANGED_KEY,
        changed.toString(),
    );
};
