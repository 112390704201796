import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Addchart from '@mui/icons-material/Addchart';

import CrudPage from 'src/components/crud/CrudPage';
import Breadcrumb from 'src/components/Breadcrumb';

import { Panel } from 'src/pages/panels/Panel';
import PanelsModal, { ProfileEntity } from 'src/components/PanelsModal';
import { Column } from 'src/components/crud/Crud.d';

import { Profile } from './Profile.d';

const ProfilesPage = () => {
    const columns = useMemo<Column<Profile>[]>(
        () => [
            {
                accessorKey: 'description',
                header: 'Perfil',
            },
        ],
        []
    );

    /**
     * MANAGE PANELS MODAL
     */
    const [panelsModalOpen, setPanelsModalOpen] = useState(false);
    const [modalProfileEntity, setModalProfileEntity] = useState<ProfileEntity | null>(null);

    const openPanelsModal = (entity: Profile) => {
        setModalProfileEntity({
            ...entity,
            panels: entity.panels?.map((p: Panel) => p.id.toString()) ?? null,
        });
        setPanelsModalOpen(true);
    };

    /**
     * HANDLE CRUD PAGE REFRESH
     */
    const [refresh, setRefresh] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Acessos', 'Perfis']} />
                </Box>
            </Stack>

            {/** FULL CRUD */}
            <CrudPage
                name={{
                    singular: 'Perfil',
                    plural: 'Perfis',
                }}
                endpoint={'/api/profiles/'}
                columns={columns}
                extraRowActions={[
                    {
                        onClick: openPanelsModal,
                        tooltip: 'Painéis',
                        icon: Addchart,
                        color: 'primary',
                    },
                ]}
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
            />

            {/** ADD PANEL'S MODAL */}
            {modalProfileEntity && (
                <PanelsModal
                    open={panelsModalOpen}
                    setOpen={setPanelsModalOpen}
                    entity={modalProfileEntity}
                    refresh={setRefresh}
                    getEndpoint={(id) => `/api/profiles/${id}/panels/`}
                    getTitle={(entity) => entity?.description ?? ''}
                />
            )}
        </>
    );
};

export default ProfilesPage;