import React, {Dispatch, SetStateAction} from 'react';

import {ProfileEntity} from 'src/components/PanelsModal';
import CrudModal from 'src/components/crud/modals/CrudModal';
import PanelsField from 'src/components/PanelsModal/PanelsField';

import api from 'src/services/api';
import {enqueueSnackbar} from 'notistack';

import {AddPanelsModalProps} from './AddPanelsModal.d';

const AddPanelsModal = (props: AddPanelsModalProps) => {
    const {open, onClose, user} = props;

    /**
     * HANDLE SUBMIT
     */
    const submitPanels = async (formData: FormData, id: number, values: any) => {
        return await api
            .put(`/api/users/${id}/profiles/`, {
                profiles: values.profiles.map((p: any) => p.id),
                user_panels: values.user_panels,
            })
            .then(() => {
                onClose();
            })
            .catch(() => {
                enqueueSnackbar('Erro ao atualizar!', { variant: 'error' });
            });
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <CrudModal
            // States
            open={open}
            entity={user}
            // Structure
            columns={[
                {
                    accessorKey: 'profile.description',
                    header: 'Perfis',
                    enableEditing: true,
                    field: {
                        type: 'search',
                        name: 'profiles',
                        endpoint: '/api/profiles/',
                        labelKey: 'description',
                        multiple: true,
                    },
                },
                {
                    accessorKey: 'panel.description',
                    header: 'Painéis',
                    enableEditing: true,
                    field: {
                        type: 'custom',
                        component: (values: ProfileEntity, setValues: Dispatch<SetStateAction<ProfileEntity>>) => (
                            <PanelsField
                                key={'panels-field'}
                                header={'Painéis do Usuário'}
                                accessorKey={'user_panels'}
                                values={values}
                                setValues={setValues}
                            />
                        ),
                    },
                },
            ]}
            // Texts
            updateTitle={`Perfis do usuário ${user?.first_name ?? ''}`}
            // Events
            onSubmit={submitPanels}
            onCancel={onClose}
        />
    );
};

export default AddPanelsModal;