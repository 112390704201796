import React from 'react';
import ReactDOM from 'react-dom/client';

import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// core styles
import './styles/styles.css';
import './styles/react-markdown.css';

// vendor styles
import 'react-datetime/css/react-datetime.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

root.render(
    <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
            <App />
        </SnackbarProvider>
    </QueryClientProvider>
);
