import React, {ChangeEvent, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';

import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import {useTheme} from '@mui/material';

import BookIcon from '@mui/icons-material/Book';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FixedIcon from '@mui/icons-material/PushPin';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuIcon from '@mui/icons-material/Menu';
import TableIcon from '@mui/icons-material/TableView';

import api from 'src/services/api';
import AppRoutes from 'src/routes/appRoutes/appRoutes';

import {getUserData, removeUserData, User} from 'src/services/storage/user';
import {getCompanyVisualData, removeCompaniesVisualData} from 'src/services/storage/company';
import {removeToken} from 'src/services/auth';

import {Group} from 'src/pages/panels/Groups';
import {PanelTree, PanelTreeItem} from 'src/pages/panels/Panel';
import {Subgroup} from 'src/pages/panels/Subgroup';

import PredictIcon from 'src/assets/img/brand/predict/predict-icon.svg';

import CollapsableNavItem from './CollapsableNavItem';
import Item, {NavItemProps} from './NavItem';


const SideNavWrapper = styled(Box)<{ show: number }>(({
                                                          theme,
                                                          show,
                                                      }) => ({
    [theme.breakpoints.up('xs')]: {
        width: '100%',
        display: show ? 'block' : 'none',
    },
    [theme.breakpoints.up('md')]: {
        width: '260px',
        display: 'block',
    },
    background: theme.palette.primary.main,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    maxHeight: '100%',
}));

const SidebarDivider = styled(Divider)(({theme}) => ({
    marginTop: '10px',
    marginBottom: '10px',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1.2,
    opacity: 0.5,
}));


const Sidebar = () => {

    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const [show, setShow] = useState(false);

    const onCollapse = () => setShow(!show);

    const NavItem = (props: Omit<NavItemProps, keyof { setShow: undefined }>) => {
        return (<Item
            {...props}
            setShow={setShow}
        />);
    };

    /**
     * LOGGED USER DATA
     */
    const [user, setUser] = useState<User | null>(null);

    /**
     * COMPANY VISUAL
     */
    const [companyLogoUrl, setCompanyLogoUrl] = useState(null);
    const [companySecondaryColor, setCompanySecondaryColor] = useState('');

    useEffect(() => {
        setCompanySecondaryColor(theme.palette.secondary.main.replace('#', ''));
    }, [theme]);


    /**
     * PANELS NAV ITEMS
     */
    const [panelsData, setPanelsData] = useState(null);
    const [panelsDataFiltered, setPanelsDataFiltered] = useState<PanelTree | null>(null);

    const handleLogoutClick = () => {
        removeToken();
        removeUserData();
        removeCompaniesVisualData();
        window.location.reload();
    };

    useEffect(() => {
        api.get(`/api/panels/my/`).then(response => {
            setPanelsData(response.data);
            setPanelsDataFiltered(response.data);
        }).catch(() => {
            enqueueSnackbar('Erro ao carregar painéis!', {variant: 'error'});
        });

        getUserData().then(user => {
            setUser(user || {});
        });

        getCompanyVisualData().then(visual => {
            setCompanyLogoUrl(visual['logo_url']);
        });
    }, [enqueueSnackbar]);

    /**
     * USER PERMISSONS
     */
    const [isStaff, setIsStaff] = useState({});

    useEffect(() => {
        // Get the subdomain of the window
        const subdomain = window.location.host.split('.')[0].toString() as string;

        // If the user does not have the subdomain
        if (!user || !user.hasOwnProperty('domains')) {
            setIsStaff(false);
            return;
        }

        if (!user.domains.hasOwnProperty(subdomain)) {
            getUserData().then(user => {
                setUser(user || {});
            });
            setIsStaff(false);
            return;
        }

        // Set the property of the json response
        setIsStaff(user.domains[subdomain]['is_staff'] ?? false);
    }, [user]);

    /**
     * FILTER PANELS
     */
    const matchDescription = (element: PanelTreeItem, searchText: string) => {
        return element.description.toLowerCase().includes(searchText);
    };

    const handleFilterPanels = (event: ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value.toLowerCase();
        const filteredPanels = Object.assign({}, JSON.parse(JSON.stringify(panelsData))) as PanelTree;

        if (searchText !== '') {
            filteredPanels.groups = filteredPanels.groups.map<Group | null>((group: Group) => {

                if (matchDescription(group, searchText)) {
                    return group;
                }

                group.subgroups = group.subgroups.map<Subgroup | null>((subgroup: Subgroup) => {

                    if (matchDescription(subgroup, searchText)) {
                        return subgroup;
                    }

                    subgroup.panels = subgroup.panels.filter(panel => matchDescription(panel, searchText));

                    if (subgroup.panels.length > 0) {
                        return subgroup;
                    }

                    return null;
                }).filter(subgroup => subgroup !== null) as Subgroup[];

                group.subgroups = group.subgroups.filter(subgroup => subgroup !== null);

                if (group.subgroups.length > 0) {
                    return group;
                }

                return null;
            }).filter(group => group !== null) as Group[];
        }

        setPanelsDataFiltered(filteredPanels);
    };

    const FixedItems = () => {
        if (!panelsDataFiltered) {
            return null;
        }
        return (
            <>
                {panelsDataFiltered.fixed.map(panel => (
                    <NavItem
                        key={panel.id}
                        title={panel.description}
                        link={'path' in panel ? panel.path : AppRoutes.Panel.path.replace(':slug', panel.id.toString())}
                        new_tab={panel.new_tab}
                        icon={FixedIcon}
                    />
                ))}
            </>
        );
    }
    const DashboardItems = () => {
        if (!panelsDataFiltered) {
            return null;
        }
        return (
            <>
                {panelsDataFiltered.groups.map(group => {
                    return (
                        <CollapsableNavItem
                            key={group.description}
                            title={group.description}
                            imageSrc={`https://img.icons8.com/material/${companySecondaryColor}/${group.icon ?? 'link'}`}
                        >
                            {group && group.subgroups.map(subgroup => {
                                return (
                                    <CollapsableNavItem
                                        key={`${group.description}-${subgroup.description}`}
                                        title={subgroup.description}
                                    >
                                        {subgroup && subgroup.panels.map(panel => {
                                            return (
                                                <NavItem
                                                    new_tab={panel.new_tab}
                                                    key={`${group.description}-${subgroup.description}-${panel.description}`}
                                                    title={panel.description}
                                                    link={AppRoutes.Panel.path.replace(':slug', panel.id.toString())}
                                                />
                                            );
                                        })}
                                    </CollapsableNavItem>
                                );
                            })}
                        </CollapsableNavItem>
                    );
                })}
            </>
        );
    };

    return (<>
        <AppBar
            position={'static'}
            sx={{
                px: 4,
                display: {
                    xs: 'block',
                    md: 'none',
                },
            }}
        >
            <Toolbar
                disableGutters
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <img alt={'Icone Predict'} src={PredictIcon} width={25}/>
                <IconButton size={'large'} edge={'start'} onClick={onCollapse} sx={{color: 'white'}}>
                    <MenuIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>

        <SideNavWrapper show={show ? 1 : 0}>

            <SimpleBarReact
                style={{maxHeight: '100%'}}
            >
                <Box
                    sx={{
                        px: 2,
                        py: 3,
                    }}
                >
                    <Stack
                        direction={'row-reverse'}
                        sx={{
                            display: {md: 'none'},
                        }}
                    >
                        <IconButton
                            size={'large'}
                            edge={'start'}
                            onClick={onCollapse}
                        >
                            <ClearIcon/>
                        </IconButton>
                    </Stack>

                    <Stack>
                        <Box
                            sx={{
                                py: 1.8,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1.2,
                            }}
                        >
                            <img
                                alt={'Logomarca Predict'}
                                src={companyLogoUrl ?? ''}
                                style={{
                                    maxWidth: '80%',
                                }}
                            />
                        </Box>

                        {/*<SidebarDivider/>*/}

                        {/*<NavItem title={'Dashboard'} link={AppRoutes.Dashboard.path} icon={PieChartIcon}/>*/}

                        <SidebarDivider/>

                        <FixedItems/>

                        {(panelsDataFiltered && panelsDataFiltered.fixed.length != 0) &&
                            <SidebarDivider/>
                        }

                        {panelsData && companySecondaryColor &&
                            <TextField
                                id={'filter_panels'}
                                variant={'standard'}
                                color={'secondary'}
                                placeholder={'buscar...'}
                                onChange={handleFilterPanels}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={'start'}>
                                            <FilterAltIcon color={'secondary'}/>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    m: 2,
                                    mt: 1,
                                    input: {
                                        color: `#${companySecondaryColor}`,
                                    },
                                }}
                            />
                        }

                        <DashboardItems/>

                        <SidebarDivider/>

                        {isStaff ? <>
                            <CollapsableNavItem title={'Cadastros'} icon={BookIcon}>
                                <NavItem title={'Grupos'} link={AppRoutes.Groups.path}/>
                                <NavItem title={'Subgrupos'} link={AppRoutes.Subgroups.path}/>
                                <NavItem title={'Painéis'} link={AppRoutes.Panels.path}/>
                            </CollapsableNavItem>
                            <CollapsableNavItem title={'Acessos'} icon={TableIcon}>
                                <NavItem title={'Usuários'} link={AppRoutes.Users.path}/>
                                <NavItem title={'Perfis'} link={AppRoutes.Profiles.path}/>
                            </CollapsableNavItem>
                            <SidebarDivider/>
                        </> : null}


                        <NavItem
                            title={'Sair'}
                            onClick={handleLogoutClick}
                            icon={MeetingRoomIcon}
                        />
                    </Stack>
                </Box>
            </SimpleBarReact>
        </SideNavWrapper>
    </>);
};

export default Sidebar;
