import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import IconPicker from 'src/components/fields/IconPicker/IconPicker';
import { Group } from 'src/pages/panels/Groups/Group.d';
import { Column } from 'src/components/crud/Crud.d';

const GroupsPage = () => {
    const renderIconPicker = (values: any, setValues: any) => {
        return (
            <Box key={'icon-picker'}>
                <Typography>Ícone</Typography>
                <IconPicker
                    value={values['icon']}
                    onChange={(v) =>
                        setValues({
                            ...values,
                            icon: v,
                        })
                    }
                />
            </Box>
        );
    };

    const columns = useMemo<Column<Group>[]>(
        () => [
            {
                accessorKey: 'description',
                header: 'Grupo',
            },
            {
                accessorKey: 'order',
                header: 'Ordem',
                hideColumn: true,
                field: {
                    type: 'number',
                    default: '1000',
                },
            },
            {
                accessorKey: 'icon',
                header: 'Ícone',
                hideColumn: true,
                field: {
                    type: 'custom',
                    default: 'link',
                    component: renderIconPicker,
                },
            },
        ],
        []
    );

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Cadastros', 'Grupos']} />
                </Box>
            </Stack>

            <CrudAPI
                name={{
                    singular: 'Grupo',
                    plural: 'Grupos',
                }}
                endpoint={'/api/panels/groups/'}
                columns={columns}
            />
        </>
    );
};

export default GroupsPage;