import React, { Dispatch, SetStateAction } from 'react';

import { DropzoneArea } from 'mui-file-dropzone';

import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';
import { columns as commonColumns, PANEL_TYPES } from 'src/pages/panels/Panels/common';
import { Columns, TData } from 'src/components/crud/Crud.d';
import { Panel } from 'src/pages/panels/Panel';

import { PdfPanelDialogProps } from './PdfPanelDialog.d';

const name = {
    singular: 'Painel de PDF',
    plural: 'Painéis de PDF',
};

const renderPdfField = (values: any, setValues: any, errors: any) => {
    return (
        <DropzoneArea
            key={'panel-droparea'}
            onChange={(e) => {
                setValues({
                    ...values,
                    file: e[0],
                    path: null,
                });
            }}
            onDelete={(e) => {
                setValues({
                    ...values,
                    file: null,
                });
            }}
            fileObjects={values['file'] ? [values['file']] : []}
            filesLimit={1}
            dropzoneText={'Insira o arquivo PDF aqui.'}
            maxFileSize={100 * 1024 * 1024} // 100 mb
        />
    );
};

const columns: Columns<Panel> = [
    ...commonColumns,
    {
        accessorKey: 'header',
        header: 'Dados do Painel',
        field: {
            type: 'header',
            separator: true,
        },
    },
    {
        accessorKey: 'file',
        header: 'Arquivo',
        field: {
            type: 'custom',
            component: renderPdfField,
        },
    },
];

const formatPayload = (formData: FormData, entity_id: number, values: Record<string, any>) => {
    if (!values.subgroup.id) {
        formData.set('subgroup', '');
    }
    formData.delete('group');
    if (!values.file) {
        formData.delete('file');
    }
    formData.set('panel_type', PANEL_TYPES.PDF);
    return formData;
};

const PdfPanelDialog = (props: PdfPanelDialogProps) => {
    const { open, setOpen, entity, setEntity, refetch } = props;

    return (
        <CreateUpdateModal
            name={name}
            columns={columns}
            endpoint={'api/panels/panels/'}
            open={open}
            setOpen={setOpen}
            entity={entity}
            setEntity={setEntity as Dispatch<SetStateAction<TData | null>>}
            formatPayload={formatPayload}
            refetch={refetch}
        />
    );
};

export default PdfPanelDialog;
