import React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import HomeRounded from '@mui/icons-material/HomeRounded';

import {BreadcrumbProps} from '../Breadcrumb';

const Breadcrumb = (props: BreadcrumbProps) => {

    const {items} = props;

    return (
        <Breadcrumbs
            sx={{
                display: {
                    xs: 'none',
                    md: 'inline',
                },
            }}
        >
            <HomeRounded fontSize={'small'}/>
            {items.map((item, index) => {
                if (index === items.length - 1) {
                    return (
                        <Typography
                            variant={'body2'}
                            color={'text.primary'}
                            key={index}
                            children={item}
                        />
                    );
                }

                return (
                    <Typography
                        variant={'body2'}
                        key={index}
                        children={item}
                    />
                );
            })}
        </Breadcrumbs>
    );
};

export default Breadcrumb;