import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import CrudPage from 'src/components/crud/CrudPage';

import { Subgroup } from 'src/pages/panels/Subgroup/Subgroup.d';
import { Columns } from 'src/components/crud/Crud.d';


const name = {
    singular: 'Subgrupo',
    plural: 'Subgrupos',
};

const columns: Columns<Subgroup> = [
    {
        accessorKey: 'description',
        header: 'Subgrupo',
    },
    {
        accessorKey: 'group.description',
        header: 'Grupo',
        field: {
            type: 'search',
            name: 'group',
            endpoint: '/api/panels/groups/',
            labelKey: (option: any) => option?.description ?? '',
        },
    },
    {
        accessorKey: 'order',
        header: 'Order',
        hideColumn: true,
        field: {
            type: 'number',
            default: '1000',
        },
    },
];

const SubgroupsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Cadastros', 'Subgrupos']} />
                </Box>
            </Stack>

            <CrudPage name={name} endpoint={'/api/panels/subgroups/'} columns={columns} />
        </>
    );
};

export default SubgroupsPage;