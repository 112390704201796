import React from 'react';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import PageIcon from '@mui/icons-material/Description';

import {Page} from 'src/pages/panels/Panels';

import { ReportListProps } from './PageList.d';

const PageList = (props: ReportListProps) => {
    const { pages, onSelect } = props;

    /**
     * RENDER COMPONENT
     */
    return (
        <List
            dense
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
            }}
        >
            {pages.map((page: Page
            ) => (
                <ListItemButton key={page.name} onClick={() => onSelect(page)}>
                    <ListItemIcon children={<PageIcon />} />
                    <ListItemText primary={page.displayName} />
                </ListItemButton>
            ))}
        </List>
    );
};

export default PageList;