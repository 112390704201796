import React from 'react';

import { useQuery } from '@tanstack/react-query';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';

import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/OpenInNew';

import api from 'src/services/api';
import Loader from 'src/components/Loader';
import Error from 'src/components/Error';

import { ReportDetailDialogProps } from './ReportDetailDialog.d';

const DetailLink = styled(Link)({
    display: 'inline-flex',
    alignItems: 'center',
});

const ReportDetailDialog = (props: ReportDetailDialogProps) => {
    const { open, onClose, appId, workspaceId, datasetId, reportId, pageId } = props;

    const fetchReportDetails = () => {
        return api
            .get(`/api/powerbi/apps/${appId}/explorer/detail/`, {
                params: {
                    workspace_id: workspaceId,
                    dataset_id: datasetId,
                    report_id: reportId,
                    page_id: pageId,
                },
            })
            .then((res) => res.data);
    };

    const { data, isLoading, isError } = useQuery({
        queryKey: ['reportDetails', appId, reportId, pageId],
        queryFn: fetchReportDetails,
        enabled: open,
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xs'}>
            <CloseIcon
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    cursor: 'pointer',
                    margin: 1,
                }}
            />
            <DialogTitle children={'Detalhes do Painel'} sx={{ mb: 2 }} />
            <DialogContent>
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={2}
                    sx={{
                        minHeight: '20vh',
                    }}
                >
                    {isError ? (
                        <Error
                            title={'Erro ao carregar detalhes do painel'}
                            subtitle={'Por favor tente novamente mais tarde'}
                            showBackButton={false}
                        />
                    ) : isLoading ? (
                        <Loader text={'Carregando detalhes do painel'} />
                    ) : (
                        <Stack direction={'column'} width={'100%'} spacing={1.5}>
                            <Typography>
                                <b>Conta: </b> {data?.dataset?.configuredBy}
                            </Typography>
                            <Divider />
                            <Typography>
                                <b>Workspace: </b>
                                <DetailLink
                                    target={'_blank'}
                                    href={`https://app.powerbi.com/groups/${data?.workspace?.id}`}
                                >
                                    {data?.workspace?.name} <LinkIcon  sx={{ fontSize:'0.9em', ml: 0.5 }} />
                                </DetailLink>
                            </Typography>
                            <Divider />
                            <Typography>
                                <b>Dataset: </b>
                                <DetailLink target={'_blank'} href={`${data?.dataset?.webUrl}/details`}>
                                    {data?.dataset?.name} <LinkIcon  sx={{ fontSize:'0.9em', ml: 0.5 }} />
                                </DetailLink>
                            </Typography>
                            <Divider />
                            <Typography>
                                <b>Tipo: </b> {data?.report?.reportType}
                            </Typography>

                            <Typography>
                                <b>Relatório: </b>
                                <DetailLink target={'_blank'} href={data?.report?.webUrl}>
                                    {data?.report?.name} <LinkIcon  sx={{ fontSize:'0.9em', ml: 0.5 }} />
                                </DetailLink>
                            </Typography>

                            <Typography>
                                <b>Aba: </b>
                                <DetailLink target={'_blank'} href={`${data?.report?.webUrl}/${data?.page?.name}`}>
                                    {data?.page?.displayName} <LinkIcon  sx={{ fontSize:'0.9em', ml: 0.5 }} />
                                </DetailLink>
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ReportDetailDialog;
