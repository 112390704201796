import React, { Fragment, useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import WorkspaceIcon from 'src/assets/img/icons/workspace.svg';
import ReportIcon from 'src/assets/img/icons/report.svg';
import PremiumIcon from 'src/assets/img/icons/premium.svg';

import { Report, Workspace } from 'src/pages/panels/Panels';

import { ReportListProps } from './ReportList.d';

const ReportList = (props: ReportListProps) => {
    const { workspaces, onSelect } = props;

    /**
     * EXPLORER CONTROL
     */
    const [openWorkspace, setOpenWorkspace] = useState<string>('');

    /**
     * RENDER COMPONENT
     */
    return (
        <List
            dense
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
            }}
        >
            {workspaces.map((workspace: Workspace) => {
                const open = openWorkspace === workspace.id;
                const hasReports = workspace.reports && workspace.reports.length > 0;

                return (
                    <Fragment key={workspace.id}>
                        <ListItemButton
                            onClick={() => {
                                if (open && hasReports) {
                                    setOpenWorkspace('');
                                    return;
                                }
                                setOpenWorkspace(workspace.id);
                            }}
                        >
                            <ListItemIcon>
                                <Box component={'img'} src={WorkspaceIcon} alt={'Workspace'} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <>
                                        {workspace.name}
                                        {workspace.isOnDedicatedCapacity && (
                                            <Box
                                                component={'img'}
                                                src={PremiumIcon}
                                                alt={'Premium'}
                                                height={'10px'}
                                                sx={{ ml: 1 }}
                                            />
                                        )}
                                    </>
                                }
                            />
                            {hasReports && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                        </ListItemButton>
                        {hasReports && (
                            <Collapse in={open} timeout={'auto'} unmountOnExit>
                                <List
                                    dense
                                    component={'div'}
                                    sx={{
                                        mx: 2,
                                        my: 1,
                                        borderRadius: 2,
                                        bgcolor: '#F8F8F8',
                                    }}
                                >
                                    {workspace.reports.map((report: Report) => (
                                        <ListItemButton
                                            key={report.id}
                                            onClick={() => {
                                                onSelect(workspace, report);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <Box component={'img'} src={ReportIcon} alt={'Report'} />
                                            </ListItemIcon>
                                            <ListItemText primary={report.name} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </Fragment>
                );
            })}
        </List>
    );
};

export default ReportList;