import React from 'react';

import { useQuery } from '@tanstack/react-query';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

import api from 'src/services/api';

import { Workspace } from 'src/pages/panels/Panels';

import ReportList from './ReportList';
import { ReportSelectorProps } from './ReportSelector.d';

const ReportSelector = (props: ReportSelectorProps) => {
    const { open, onClose, appId, onSelect } = props;

    /**
     * WORKSPACE LOAD
     */
    const fetchWorkspaces = async () => {
        return api.get(`/api/powerbi/apps/${appId}/explorer/workspaces/tree/`).then((res) => res.data.value);
    };

    const { data: workspaces, isLoading: workspacesLoading } = useQuery<void, Error, Workspace[]>({
        queryKey: ['workspaces', appId],
        queryFn: fetchWorkspaces,
        enabled: open,
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog onClose={onClose} open={open} fullWidth>
            <CloseIcon
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    cursor: 'pointer',
                    margin: 1,
                }}
            />
            <DialogTitle children={'Selecione um Relatório'} />
            <DialogContent>
                {workspacesLoading ? (
                    <Stack
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                            height: '50vh',
                        }}
                        children={<CircularProgress />}
                    />
                ) : (
                    workspaces && <ReportList workspaces={workspaces} onSelect={onSelect} />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ReportSelector;
