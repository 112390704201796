import React, {useState} from 'react';

import {enqueueSnackbar} from 'notistack';
import {useMutation} from '@tanstack/react-query';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import AddUserIcon from '@mui/icons-material/PersonAdd';

import api from 'src/services/api';

import {InviteUserModalProps} from './InviteUserModal.d';

const InviteUserModal = (props: InviteUserModalProps) => {
    const {open, onClose} = props;

    /**
     * VALUES CONTROL
     */
    const [email, setEmail] = useState('');

    /**
     * SUBMIT CONTROL
     */
    const submitAdd = async () => {
        return await api.post('/api/users/company/invite/', {email});
    };

    const {mutateAsync, isLoading} = useMutation(submitAdd, {
        onError: (error) => {
            enqueueSnackbar('Erro ao adicionar usuário!', {variant: 'error'});
        },
        onSuccess: () => {
            enqueueSnackbar('Usuário convidado com sucesso!', {variant: 'success'});
            onClose();
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog fullWidth open={open} onClose={onClose} >
            <DialogTitle>Convidar Usuário</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label={'E-mail'}
                    variant={'outlined'}
                    margin={'normal'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions
                sx={{
                    p: '1.25rem',
                    display: 'flex',
                    backgroundColor: '#ECECEC',
                    justifyContent: 'end',
                }}
            >
                <Button variant={'outlined'} disabled={isLoading} onClick={onClose} children={'Cancelar'}/>
                <Button
                    variant={'contained'}
                    children={'Convidar'}
                    startIcon={isLoading ? <CircularProgress size={16}/> : <AddUserIcon/>}
                    disabled={isLoading}
                    onClick={async () => {
                        await mutateAsync();
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default InviteUserModal;