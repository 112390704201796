import React from 'react';
import {useNavigate} from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import {NavItemProps} from '../NavItem';

const NavItem = (props: NavItemProps) => {

    const {
        title,
        icon: Icon,
        image,
        link,
        onClick,
        setShow,
        new_tab = false,
    } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick();
            return;
        }

        setShow(false);

        if (link) {
            if (new_tab) {
                window.open(link, '_blank');
                return;
            }
            navigate(link);
        }

    };

    return (<Tooltip
        title={title}
        placement={'right-start'}
        TransitionComponent={Zoom}
        arrow
    >
        <Button
            disableElevation
            onClick={handleClick}
            sx={{
                py: 1.2,
                px: 1.5,
                display: 'flex',
                justifyContent: 'start',
                '&:hover': {
                    backgroundColor: '#FFFFFF66',
                },
            }}
            color={'secondary'}
        >
            {Icon ? (<Icon fontSize={'small'} sx={{mr: 1}}/>) : null}
            {image ? (<img src={image} width={20} height={20} alt={'sidebar'}/>) : null}

            <Typography
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                fontSize={'0.9rem'}
                sx={{
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                }}
            >
                {title}
            </Typography>
        </Button>
    </Tooltip>);
};

export default NavItem;