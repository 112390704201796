import React from 'react';

import {useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';

import api from 'src/services/api';
import {setCurrentAccount} from 'src/services/storage/account/account';

import Loader from 'src/components/Loader';

import ExternalDisplay from './ExternalDisplay';
import PDFDisplay from './PDFDisplay';
import PowerBIDisplay from './PowerBIDisplay';
import EmbedDisplay from './EmbedDisplay';

import {EmbedPanel, ExternalPanel, Panel, PDFPanel, PowerBIPanel} from './Panel.d';
import Error from 'src/components/Error';

const PanelPage = () => {
    const {slug} = useParams();

    /**
     * GET PANEL AND LOAD INTO IFRAME
     */
    const fetchPanel = async () => {
        return api.get(`/api/panels/my/${slug}/`).then((response) => {
            const panel = response.data;
            if (panel.type === 'PBI') {
                setCurrentAccount(panel.account);
            }
            return panel;
        });
    };

    const {
        data: panel,
        isLoading,
        isError,
    } = useQuery<void, Error, Panel>({
        queryKey: ['panel', slug],
        queryFn: fetchPanel,
    });

    /**
     * RENDER COMPONENT
     */
    if (isLoading) {
        return <Loader text={'Carregando Painel'}/>;
    }

    if (isError || !panel) {
        return <Error title={'Erro ao carregar dados do painel'} subtitle={'Tente novamente mais tarde.'}/>;
    }

    if (panel.panel_type === 'PBI') {
        return <PowerBIDisplay panel={panel as PowerBIPanel}/>;
    }

    if (panel.panel_type === 'PDF') {
        return <PDFDisplay panel={panel as PDFPanel}/>;
    }

    if (panel.panel_type === 'EMB') {
        return <EmbedDisplay panel={panel as EmbedPanel}/>;
    }

    return <ExternalDisplay panel={panel as ExternalPanel}/>;
};

export default PanelPage;
