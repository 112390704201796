import React from 'react';

import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import LoadPredict from '../animation/LoadPredict';
import {PreloaderProps} from '../Preloader';

const Wrapper = styled(Box)<{ show: number }>(({
                                                   theme,
                                                   show,
                                               }) => ({
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    left: 0,
    height: show ? '100vh' : '0', // Set height based on the 'show' prop
    width: '100%',
    backgroundColor: '#EFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'height 0.4s ease',
}));


const Preloader = (props: PreloaderProps) => {

    const {show} = props;

    return (
        <Wrapper show={show ? 1 : 0}>
            {show && (
                <LoadPredict/>
            )}
        </Wrapper>
    );
};

export default Preloader;