import React, { Dispatch, SetStateAction } from 'react';

import ExternalPanelDialog from './ExternalPanelDialog';
import PdfPanelDialog from './PdfPanelDialog';
import PBILinkPanelDialog from './PBILinkPanelDialog';
import PBIEmbedPanelDialog from './PBIEmbedPanelDialog';

import { PANEL_TYPES } from 'src/pages/panels/Panels/common';
import { EmbedPanel, ExternalPanel, PDFPanel, PowerBIPanel } from 'src/pages/panels/Panel';

import { CreateUpdatePanelModalProps } from './CreateUpdatePanelModal.d';

const CreateUpdatePanelModal = (props: CreateUpdatePanelModalProps) => {
    const { type, open, setOpen, entity, setEntity, refetch } = props;

    const dialogProps = {
        open,
        setOpen,
        refetch,
    };

    if (type === PANEL_TYPES.EXT) {
        return (
            <ExternalPanelDialog
                {...dialogProps}
                entity={entity as ExternalPanel}
                setEntity={setEntity as Dispatch<SetStateAction<null | ExternalPanel>>}
            />
        );
    }

    if (type === PANEL_TYPES.PDF) {
        return (
            <PdfPanelDialog
                {...dialogProps}
                entity={entity as PDFPanel}
                setEntity={setEntity as Dispatch<SetStateAction<null | PDFPanel>>}
            />
        );
    }

    if (type === PANEL_TYPES.EMB) {
        return (
            <PBIEmbedPanelDialog
                {...dialogProps}
                entity={entity as EmbedPanel}
                setEntity={setEntity as Dispatch<SetStateAction<null | EmbedPanel>>}
            />
        );
    }

    return (
        <PBILinkPanelDialog
            {...dialogProps}
            entity={entity as PowerBIPanel}
            setEntity={setEntity as Dispatch<SetStateAction<null | PowerBIPanel>>}
        />
    );
};

export default CreateUpdatePanelModal;
