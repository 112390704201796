/**
 * STORE USER INFORMATION ON LOCALSTORAGE
 */


import api from '../../api';
import {User} from '../user';

/**
 * Identifier for the user data on localstorage
 * @type {string}
 */
export const USER_KEY = '@predicthub-user';

/**
 * Set user data in localstorage
 * @param {object} user - The user data to be stored in localstorage
 */
export const setUserData = (user: User | null) => {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
};

/**
 * Get user data from localstorage
 * @returns {object | null} - Returns the user data if present in localstorage, null otherwise
 */
export const getUserData = async () => {
    let userData = null;
    const localData = localStorage.getItem(USER_KEY);

    if (localData) {
        userData = JSON.parse(localData);
    }

    // If no user data is found, call the api
    if (userData == null) {
        await fetchUserData();
        userData = JSON.parse(localStorage.getItem(USER_KEY) ?? '');
    }

    return userData;
};

/**
 * Remove user data from localstorage
 */
export const removeUserData = () => {
    localStorage.removeItem(USER_KEY);
};

/**
 * Fetch user data from the API
 */
export const fetchUserData = async () => {
    try {
        const response = await api.get('/api/users/my/');
        setUserData(response.data);
    } catch (err) {
        setUserData(null);
    }
};