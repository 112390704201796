import React, {useEffect, useState} from 'react';

import api from '../../../services/api';

import TenantNotFound from '../../../pages/errors/TenantNotFoundError';

import {RouteWithTenantProps} from '../RouteWithTenant';

const useTenantVerification = () => {

    const [isValidTenant, setIsValidTenant] = useState(true);

    useEffect(() => {
        const verifyTenant = async () => {
            try {
                const response = await api.get(`/api/companies/check/`);
                setIsValidTenant(response.data.isValid);
                document.title = `${response.data.company ?? 'Predict'} - Predict HUB`;
            } catch (error) {
                setIsValidTenant(false);
            }
        };

        verifyTenant();
    }, []);

    return isValidTenant;

};

export default function RouteWithTenant(props: RouteWithTenantProps) {

    const {children} = props;

    return (!useTenantVerification() ? <TenantNotFound/> : children);
}
