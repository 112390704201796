import { EmbedTokensMap } from 'src/services/storage/embed/embed.d';

import api from 'src/services/api';

export const STORAGE_KEY = '@predicthub-embed';

export const setEmbedTokensMap = (embed: EmbedTokensMap) => {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(embed));
};

export const hasEmbedTokensMap = () => {
    return sessionStorage.getItem(STORAGE_KEY) !== null;
};

export const hasEmbedToken = (appId: number): boolean => {
    if (!hasEmbedTokensMap()) {
        return false;
    }
    return !!JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '{}')[appId];
};

export const refreshEmbedToken = async (appId: number) => {
    const res = await api.post(`/api/powerbi/apps/${appId}/embed-token/refresh/`);
    const token = res.data.token;
    setEmbedTokensMap({ [appId]: token });
    return token;
};

export const fetchEmbedToken = async (appId: number) => {
    const res = await api.get(`/api/powerbi/apps/${appId}/embed-token/`);
    const token = res.data.token;
    setEmbedTokensMap({ [appId]: token });
    return token;
};

export const getEmbedToken = async (appId: number): Promise<string> => {
    if (!hasEmbedToken(appId)) {
        return fetchEmbedToken(appId);
    }
    return JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '{}')[appId];
};
