import React, {MouseEvent, ReactEventHandler, SyntheticEvent, useEffect, useState} from 'react';

import axios from 'axios';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';

import {IconPickerProps} from '../IconPicker';

const IconPicker = (props: IconPickerProps) => {

    const {
        onChange,
        value,
    } = props;

    /**
     * POPOVER CONTROL
     */
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorElement);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    useEffect(() => {
        setSelectedIcon(value ? value : 'link');
    }, [value]);

    /**
     * SEARCH CONTROL
     */
    const [searchText, setSearchText] = useState('');
    const [searchIcons, setSearchIcons] = useState([]);

    useEffect(() => {
        if (searchText) {

            // Query String Params
            const params = [
                `term=${searchText}`,
                `platform=m_rounded`,
                `language=pt-BR`,
                `amount=50`,
            ];

            axios({
                method: 'get',
                url: 'https://search.icons8.com/api/iconsets/v5/search?' + params.join('&'),
            }).then(res => {
                setSearchIcons(res.data.icons.map((icon: any) => icon.commonName));
            });
        }
    }, [searchText]);

    const hideParent = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        const buttonElement = event.currentTarget.closest('button');
        if (buttonElement) {
            buttonElement.style.display = 'none'; // Hide the parent Button element
        }
    };

    /**
     * SELECTION CONTROL
     */
    const [selectedIcon, setSelectedIcon] = useState('');

    return (<>
        <Button
            variant={'outlined'}
            onClick={handleClick}
        >
            <img
                src={`https://img.icons8.com/material/5D5D5D/${selectedIcon}`}
                alt={selectedIcon}
            />
        </Button>
        <Popover
            open={open}
            anchorEl={anchorElement}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Stack
                sx={{
                    width: '385px',
                    maxWidth: '80vw',
                    maxHeight: '250px',
                }}
            >
                <TextField
                    size={'small'}
                    fullWidth={true}
                    sx={{p: 1}}
                    InputProps={{
                        endAdornment: <InputAdornment position={'end'}><SearchIcon/></InputAdornment>,
                    }}
                    value={searchText}
                    onChange={(event) => {
                        setSearchText(event.target.value);
                    }}
                />
                <Grid container justifyContent={'center'}>
                    {searchIcons && searchIcons.map(iconName => {
                        const color = iconName === selectedIcon ? '1976D2' : '5D5D5D';
                        return (<Button
                            key={`icon-${iconName}`}
                            onClick={() => {
                                onChange(iconName);
                                setSelectedIcon(iconName);
                                setAnchorElement(null);
                            }}
                        >
                            <img
                                src={`https://img.icons8.com/material/${color}/${iconName}`}
                                onError={hideParent}
                            />
                        </Button>);
                    })}
                </Grid>
            </Stack>
        </Popover>
    </>);
};

export default IconPicker;