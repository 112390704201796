import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';
import PageIcon from '@mui/icons-material/Description';

import {Page} from 'src/pages/panels/Panels/Panels.d';

import PageSelector from './PageSelector';
import {PageSelectorFieldProps} from './PageSelectorField.d';

const PageSelectorField = (props: PageSelectorFieldProps) => {
    const {value, onChange, error, appId, reportId} = props;

    const [open, setOpen] = useState(false);

    /**
     * RENDER COMPONENT
     */
    const selectorDisabled = !appId || !reportId;

    return (
        <>
            <TextField
                type={'text'}
                label={'Id da Página'}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                helperText={error}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={'start'}>
                            <PageIcon
                                sx={{
                                    color: 'gray',
                                }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <Tooltip title={'Buscar Página'}>
                            <span>
                                <IconButton
                                    disabled={selectorDisabled}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                    children={<SearchIcon/>}
                                />
                            </span>
                        </Tooltip>
                    ),
                }}
            />

            {!selectorDisabled && (
                <PageSelector
                    appId={appId}
                    reportId={reportId}
                    open={open}
                    onSelect={(page: Page) => {
                        onChange(page.name);
                        setOpen(false);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default PageSelectorField;