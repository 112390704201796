import React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Footer = () => {
    return (<footer>
        <Typography sx={{my: 5}}>
            Desenvolvido por
            <Link
                href={'https://predictconsultoria.com.br/'}
                target={'_blank'}
                sx={{textDecoration: 'none'}}
                children={' Predict Consultoria'}
            />
        </Typography>
    </footer>);
};

export default Footer;
