import React, { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import CheckboxTree, { Node } from 'react-checkbox-tree';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ExpandLessIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import ExpandMoreIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import api from 'src/services/api';

import { Panel, Panels, PanelTreeGroups } from 'src/pages/panels/Panel';
import { Group } from 'src/pages/panels/Groups';
import { Subgroup } from 'src/pages/panels/Subgroup';

import { PanelsFieldProps } from './PanelsField.d';

const PanelsField = (props: PanelsFieldProps) => {
    const { accessorKey, header, values, setValues } = props;

    /**
     * FETCH PANELS TREE
     */
    const [treeFixedItems, setTreeFixedItems] = useState<Panels>([]);
    const [treeItems, setTreeItems] = useState<PanelTreeGroups>([]);

    useEffect(() => {
        const fetchPanelsTree = () => {
            api.get('/api/panels/tree/')
                .then((res) => {
                    if (!res?.data?.results?.groups) {
                        throw new Error('Erro ao carregar painéis.');
                    }

                    // Remove fixed items from tree
                    const itemIds = res?.data?.results?.groups
                        .map((group: Group) =>
                            group.subgroups.map((subgroup: Subgroup) => subgroup.panels.map((panel: Panel) => panel.id))
                        )
                        .flat(2);
                    const fixedItems = res?.data?.results?.fixed.filter((panel: Panel) => !itemIds.includes(panel.id));

                    setTreeItems(res?.data?.results?.groups);
                    setTreeFixedItems(fixedItems);
                })
                .catch(() => {
                    enqueueSnackbar('Erro ao carregar painéis.');
                });
        };

        fetchPanelsTree();
    }, [values.id]);

    /**
     * PANELS FIELD COMPONENT
     */
    const [panelsNodes, setPanelsNodes] = useState<Node[]>([]);
    const [panelsExpanded, setPanelsExpanded] = useState<string[]>([]);

    useEffect(() => {
        let nodes_grouped = treeItems.map(
            (group: Group, groupIndex: number): Node => ({
                value: `g-${groupIndex}`,
                label: group.description,
                children: group.subgroups.map(
                    (subgroup: Subgroup, subGroupIndex: number): Node => ({
                        value: `g-${groupIndex}-s-${subGroupIndex}`,
                        label: subgroup.description,
                        children: subgroup.panels.map(
                            (panel: Panel): Node => ({
                                value: panel.id.toString(),
                                label: panel.description,
                            })
                        ),
                    })
                ),
            })
        ) as Node[];
        let nodes_fixed = treeFixedItems.map((panel: Panel) => ({
            value: panel.id.toString(),
            label: panel.description,
        })) as Node[];
        setPanelsNodes([...nodes_grouped, ...nodes_fixed]);
    }, [treeItems]);

    return (
        <Box
            sx={{
                border: 1,
                borderColor: 'lightgray',
                borderRadius: 1,
            }}
        >
            <Typography
                variant={'caption'}
                children={header}
                sx={{
                    position: 'relative',
                    top: -10,
                    left: 2,
                    px: 1,
                    py: 0,
                    bgcolor: 'white',
                    color: 'gray',
                }}
            />

            <Box
                sx={{
                    px: 1,
                    pb: 2,
                }}
            >
                <CheckboxTree
                    nodes={panelsNodes}
                    checked={values[accessorKey]}
                    expanded={panelsExpanded}
                    onCheck={(checked) => setValues({ ...values, [accessorKey]: checked })}
                    onExpand={(expanded) => setPanelsExpanded(expanded)}
                    showNodeIcon={false}
                    icons={{
                        check: <CheckBoxIcon />,
                        uncheck: <CheckBoxOutlineBlankIcon />,
                        halfCheck: <IndeterminateCheckBoxIcon />,
                        expandClose: <ExpandLessIcon />,
                        expandOpen: <ExpandMoreIcon />,
                    }}
                />
            </Box>
        </Box>
    );
};

export default PanelsField;