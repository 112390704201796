import React, {Dispatch, SetStateAction} from 'react';

import {enqueueSnackbar} from 'notistack';
import {Node} from 'react-checkbox-tree';

import api from '../../services/api';

import CrudModal from '../crud/modals/CrudModal';
import {PanelsModalProps, ProfileEntity} from './PanelsModal.d';
import PanelsField from './PanelsField';

const PanelsModal = (props: PanelsModalProps) => {

    const {
        open,
        setOpen,
        entity,
        refresh,
        getEndpoint,
        getTitle,
    } = props;


    /**
     * HANDLE MODAL BUTTONS
     */
    const handlePanelsCancel = () => {
        setOpen(false);
    };

    const handlePanelsSubmit = async (formData: FormData, id: number, values: any) => {
        return await api.put(getEndpoint(id), {
            panels: values.panels ?? [],
        }).then(() => {
            enqueueSnackbar('Painéis atualizados com sucesso!', {variant: 'success'});
            setOpen(false);
            refresh(prev => !prev);
        }).catch(() => {
            enqueueSnackbar('Erro ao atualizar painéis!', {variant: 'error'});
        });
    };


    /**
     * RENDER COMPONENT
     */
    return (
        <CrudModal
            // States
            open={open}
            entity={entity}

            // Structure
            columns={[
                {
                    accessorKey: 'panel.description',
                    header: 'Painéis',
                    enableEditing: true,
                    field: {
                        type: 'custom',
                        component: (values: ProfileEntity, setValues: Dispatch<SetStateAction<ProfileEntity>>) => (
                            <PanelsField
                                key={'panels-field'}
                                header={'Painéis do Perfil'}
                                accessorKey={'panels'}
                                values={values}
                                setValues={setValues}
                            />
                        ),
                    },
                },
            ]}

            // Texts
            updateTitle={`Painéis de ${getTitle(entity)}`}

            // Events
            onSubmit={handlePanelsSubmit}
            onCancel={handlePanelsCancel}
        />
    );

};

export default PanelsModal;
