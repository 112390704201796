import React, { Dispatch, SetStateAction } from 'react';

import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';
import {columns as commonColumns, PANEL_TYPES} from 'src/pages/panels/Panels/common';
import { Columns, TData } from 'src/components/crud/Crud.d';
import { Panel } from 'src/pages/panels/Panel';

import { PowerBIPanelDialogProps } from 'src/pages/panels/Panels/CreateUpdatePanelModal/PBILinkPanelDialog/PBILinkPanelDialog.d';

const name = {
    singular: 'Painel de PowerBI',
    plural: 'Painéis de PowerBI',
};

const columns: Columns<Panel> = [
    ...commonColumns,
    {
        accessorKey: 'header',
        header: 'Dados do Painel',
        field: {
            type: 'header',
            separator: true,
        },
    },
    {
        accessorKey: 'path',
        header: 'Link',
    },
    {
        accessorKey: 'account',
        header: 'Conta',
    },
    {
        accessorKey: 'workspace',
        header: 'Workspace',
    },
    {
        accessorKey: 'report',
        header: 'Relatório',
    },
    {
        accessorKey: 'tab',
        header: 'Aba',
    },
];

const formatPayload = (formData: FormData, entity_id: number, values: Record<string, any>) => {
    if (!values.subgroup.id) {
        formData.set('subgroup', '');
    }
    formData.delete('group');
    formData.set('panel_type',  PANEL_TYPES.PBI);
    return formData;
};

const PBILinkPanelDialog = (props: PowerBIPanelDialogProps) => {
    const { open, setOpen, entity, setEntity, refetch } = props;

    return (
        <CreateUpdateModal
            name={name}
            columns={columns}
            endpoint={'api/panels/panels/'}
            open={open}
            setOpen={setOpen}
            entity={entity}
            setEntity={setEntity as Dispatch<SetStateAction<TData | null>>}
            formatPayload={formatPayload}
            refetch={refetch}
        />
    );
};

export default PBILinkPanelDialog;
