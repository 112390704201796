import React from 'react';

import { useQuery } from '@tanstack/react-query';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import CloseIcon from '@mui/icons-material/Close';

import { getCompanyVisualData } from 'src/services/storage/company';
import { PANEL_TYPES, TYPE_ICON_MAPS } from 'src/pages/panels/Panels/common';

import TypeButton from './TypeButton';
import { TypeSelectorDialogProps } from './TypeSelectorDialog.d';

const TypeSelectorDialog = (props: TypeSelectorDialogProps) => {
    const { onSelect, open, onClose } = props;

    const fetchAllowEmbed = async () => {
        return await getCompanyVisualData().then((data) => data.allow_panel_embed);
    }

    const { data: allowEmbed, isSuccess: allowEmbedSuccess } = useQuery<void, Error, boolean>({
        queryKey: ['allowEmbed'],
        queryFn: fetchAllowEmbed,
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} onClose={onClose}>
            <CloseIcon
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    cursor: 'pointer',
                    margin: 1,
                }}
            />
            <DialogTitle children={'Selecione o tipo'} />
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    alignItems={'center'}
                    justifyContent={'center'}
                    columns={2}
                    sx={{
                        p: 2,
                    }}
                >
                    <Grid item>
                        <TypeButton
                            onClick={() => onSelect(PANEL_TYPES.PBI)}
                            icon={TYPE_ICON_MAPS[PANEL_TYPES.PBI]}
                            label={'Power BI Link'}
                        />
                    </Grid>
                    {allowEmbedSuccess && allowEmbed && (
                        <Grid item>
                            <TypeButton
                                onClick={() => onSelect(PANEL_TYPES.EMB)}
                                icon={TYPE_ICON_MAPS[PANEL_TYPES.EMB]}
                                label={'Power BI Embed'}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <TypeButton
                            onClick={() => onSelect(PANEL_TYPES.EXT)}
                            icon={TYPE_ICON_MAPS[PANEL_TYPES.EXT]}
                            label={'Link'}
                        />
                    </Grid>
                    <Grid item>
                        <TypeButton
                            onClick={() => onSelect(PANEL_TYPES.PDF)}
                            icon={TYPE_ICON_MAPS[PANEL_TYPES.PDF]}
                            label={'PDF'}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TypeSelectorDialog;
