import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppRoutes from 'src/routes/appRoutes';

// components
import RouteWithSidebar from './components/RouteWithSidebar/RouteWithSidebar';
import RouteWithTenant from './components/RouteWithTenant/RouteWithTenant';

// pages
import Dashboard from 'src/pages/dashboard/Dashboard';
import DocsPowerBIApp from '../pages/docs/PowerBIApp';
import GroupsPage from 'src/pages/panels/Groups';
import SubgroupsPage from 'src/pages/panels/Subgroup';
import PanelsPage from 'src/pages/panels/Panels';
import Settings from 'src/pages/user/Settings';
import ProfilesPage from 'src/pages/access/Profiles';
import PanelPage from 'src/pages/panels/Panel';
import Users from 'src/pages/access/Users';
import AppsPage from 'src/pages/powerbi/Apps';

function PrivateAppRoutes() {
    return (
        <Routes>
            {/* user */}
            <Route path={AppRoutes.UserSettings.path} element={<RouteWithSidebar component={Settings} />} />

            {/* dashboard */}
            <Route path={AppRoutes.Dashboard.path} element={<RouteWithSidebar component={Dashboard} />} />

            {/* panels */}
            <Route path={AppRoutes.Panel.path} element={<RouteWithSidebar component={PanelPage} fullFrame={true} />} />

            {/* registers */}
            <Route path={AppRoutes.Groups.path} element={<RouteWithSidebar component={GroupsPage} />} />
            <Route path={AppRoutes.Subgroups.path} element={<RouteWithSidebar component={SubgroupsPage} />} />
            <Route path={AppRoutes.Panels.path} element={<RouteWithSidebar component={PanelsPage} />} />

            {/* powerbi */}
            <Route path={AppRoutes.Apps.path} element={<RouteWithSidebar component={AppsPage} />} />

            {/* access */}
            <Route path={AppRoutes.Users.path} element={<RouteWithSidebar component={Users} />} />
            <Route path={AppRoutes.Profiles.path} element={<RouteWithSidebar component={ProfilesPage} />} />

            {/* docs */}
            <Route path={AppRoutes.DocsPowerBIApp.path} element={<RouteWithSidebar component={DocsPowerBIApp} />} />

            {/* error pages */}
            <Route path={'*'} element={<Navigate to={AppRoutes.Dashboard.path} />} />
        </Routes>
    );
}

export default function PrivateRoutes() {
    return (
        <RouteWithTenant>
            <PrivateAppRoutes />
        </RouteWithTenant>
    );
}