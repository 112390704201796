import React, { Dispatch, SetStateAction, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';

import DataObjectIcon from '@mui/icons-material/DataObject';
import LinkIcon from '@mui/icons-material/OpenInNew';

import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';
import { columns as commonColumns, PANEL_TYPES } from 'src/pages/panels/Panels/common';
import { Columns, TData } from 'src/components/crud/Crud.d';
import { EmbedPanel } from 'src/pages/panels/Panel';

import AppRoutes from 'src/routes/appRoutes';
import getNestedValue from 'src/components/utils/getNestedValue';

import DatasetIcon from 'src/assets/img/icons/dataset.svg';
import WorkspaceIcon from 'src/assets/img/icons/workspace.svg';

import ReportSelectorField from './ReportSelectorField';
import PageSelectorField from './PageSelectorField';
import { PBIEmbedDialogProps } from './PBIEmbedPanelDialog.d';
import ReportDetailDialog from './ReportDetailDialog';

const name = {
    singular: 'Painel Embed de PowerBI',
    plural: 'Painéis Embed de PowerBI',
};

const formatPayload = (formData: FormData, entity_id: number, values: Record<string, any>) => {
    if (!values.subgroup.id) {
        formData.set('subgroup', '');
    }
    formData.delete('group');
    formData.set('panel_type', PANEL_TYPES.EMB);
    return formData;
};
const renderPanelField = (
    values: EmbedPanel,
    setValues: Dispatch<SetStateAction<EmbedPanel>>,
    errors: Record<string, any>
) => {
    return (
        <ReportSelectorField
            key={'report_id'}
            appId={values.app?.id}
            value={values.report_id}
            error={getNestedValue(errors, 'report_id', '')}
            onChange={(newValue: string) => {
                setValues({
                    ...values,
                    report_id: newValue,
                });
            }}
            onSelect={(workspaceId: string, reportId: string, datasetId: string) => {
                setValues({
                    ...values,
                    workspace_id: workspaceId,
                    report_id: reportId,
                    dataset_id: datasetId,
                });
            }}
        />
    );
};

const renderPageField = (
    values: EmbedPanel,
    setValues: Dispatch<SetStateAction<EmbedPanel>>,
    errors: Record<string, any>
) => {
    return (
        <PageSelectorField
            key={'page_id'}
            appId={values.app?.id}
            reportId={values.report_id}
            value={values.page_id}
            error={getNestedValue(errors, 'page_id', '')}
            onChange={(newValue) => {
                setValues({
                    ...values,
                    page_id: newValue,
                });
            }}
        />
    );
};

const PBIEmbedPanelDialog = (props: PBIEmbedDialogProps) => {
    const { open, setOpen, entity, setEntity, refetch } = props;

    const [detailOpen, setDetailOpen] = useState(false);

    /**
     * CRUD COLUMNS
     */
    const columns: Columns<EmbedPanel> = [
        ...(commonColumns as unknown as Columns<EmbedPanel>),
        {
            accessorKey: 'header',
            header: 'Dados do Painel',
            field: {
                type: 'header',
                separator: true,
            },
        },
        {
            accessorKey: 'app.description',
            header: 'PowerBI App',
            field: {
                type: 'search',
                name: 'app',
                endpoint: '/api/powerbi/apps/',
                labelKey: 'description',
                inputAction: {
                    label: <LinkIcon fontSize={'small'} />,
                    onClick: () => window.open(AppRoutes.Apps.path, '_blank'),
                },
            },
        },
        {
            accessorKey: 'report_id',
            header: 'Id do Painel',
            field: {
                type: 'custom',
                component: renderPanelField,
            },
        },
        {
            accessorKey: 'workspace_id',
            header: 'Id do Workspace',
            field: {
                type: 'text',
                InputProps: {
                    startAdornment: (
                        <InputAdornment position={'start'}>
                            <img src={WorkspaceIcon} alt={'workspace-icon'} />
                        </InputAdornment>
                    ),
                },
            },
        },
        {
            accessorKey: 'dataset_id',
            header: 'Id do Dataset',
            field: {
                type: 'text',
                InputProps: {
                    startAdornment: (
                        <InputAdornment position={'start'}>
                            <img src={DatasetIcon} alt={'dataset-icon'} />
                        </InputAdornment>
                    ),
                },
            },
        },
        {
            accessorKey: 'page_id',
            header: 'Id da Página',
            field: {
                type: 'custom',
                component: renderPageField,
            },
        },
    ];

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <CreateUpdateModal
                name={name}
                columns={columns}
                endpoint={'api/panels/panels/'}
                open={open}
                setOpen={setOpen}
                entity={entity}
                setEntity={setEntity as Dispatch<SetStateAction<TData | null>>}
                formatPayload={formatPayload}
                refetch={refetch}
                extraActions={
                    <Button
                        variant={'outlined'}
                        startIcon={<DataObjectIcon />}
                        onClick={() => setDetailOpen(true)}
                        children={'Ver Dados'}
                        disabled={
                            !entity?.app?.id ||
                            (!entity?.workspace_id && !entity?.report_id && !entity?.page_id && !entity?.dataset_id)
                        }
                    />
                }
            />

            {entity && entity?.app?.id && (
                <ReportDetailDialog
                    open={detailOpen}
                    onClose={() => setDetailOpen(false)}
                    appId={entity.app.id}
                    workspaceId={entity.workspace_id}
                    datasetId={entity.dataset_id}
                    reportId={entity.report_id}
                    pageId={entity.page_id}
                />
            )}
        </>
    );
};

export default PBIEmbedPanelDialog;
