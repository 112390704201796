import React, {useEffect, useState} from 'react';

import Markdown from 'react-markdown';

import PowerBIAppMD from 'docs/powerbiapp.md';

const PowerBIApp = () => {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch(PowerBIAppMD)
            .then((res) => res.text())
            // Fix image paths
            .then((text) => text.replaceAll('../public', ''))
            .then((text) => setMarkdown(text));
    });

    return <Markdown className={'reactMarkDown'} children={markdown} />;
};

export default PowerBIApp;
