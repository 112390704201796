import React from 'react';

import { enqueueSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import LinkIcon from '@mui/icons-material/OpenInNew';
import RefreshTokenIcon from '@mui/icons-material/Refresh';

import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { RowAction } from 'src/components/crud/Crud.d';

import { App } from './Apps.d';
import api from 'src/services/api';
import AppRoutes from 'src/routes/appRoutes';

const endpoint = '/api/powerbi/apps/';

const name = {
    singular: 'PowerBI App',
    plural: 'PowerBI Apps',
};

const columns = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
    {
        accessorKey: 'client_id',
        header: 'Client ID',
        hideColumn: true,
    },
    {
        accessorKey: 'client_secret',
        header: 'Client Secret',
        hideColumn: true,
    },
    {
        accessorKey: 'tenant_id',
        header: 'Tenant ID',
        hideColumn: true,
    },
    {
        accessorKey: 'username',
        header: 'Usuário',
    },
    {
        accessorKey: 'password',
        header: 'Senha',
        hideColumn: true,
    },
];

const extraRowActions: RowAction<App>[] = [
    {
        icon: RefreshTokenIcon,
        tooltip: 'Atualizar Token',
        onClick: (entity) =>
            api
                .post(`/api/powerbi/apps/${entity.id}/token/refresh/`)
                .then(() => enqueueSnackbar('Token atualizado com sucesso', { variant: 'success' }))
                .catch(() => enqueueSnackbar('Erro ao atualizar token', { variant: 'error' })),
    },
];

const AppsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Configurações', 'PowerBI', 'Apps']} />
                </Box>
            </Stack>

            <CrudAPI
                name={name}
                endpoint={endpoint}
                columns={columns}
                extraRowActions={extraRowActions}
                extraCrudActions={() => (
                    <Stack direction={'row'} alignItems={'center'}>
                        <Button
                            variant={'outlined'}
                            startIcon={<LinkIcon />}
                            onClick={() => window.open(AppRoutes.DocsPowerBIApp.path, '_blank')}
                            children={'Instruções'}
                        />
                    </Stack>
                )}
            />
        </>
    );
};

export default AppsPage;
