import React, {useEffect, useMemo, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import {createTheme} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

import 'dayjs/locale/pt-br';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {ptBR} from '@mui/x-date-pickers/locales';
import {ptBR as corePtBr} from '@mui/material/locale';

import {isAuthenticated} from './services/auth';
import PublicRoutes from './routes/publicRoutes';
import PrivateRoutes from './routes/privateRoutes';
import {getCompanyVisualData} from './services/storage/company/company';


const mainPalette = {
    primary: {
        main: '#182145',
    },
    secondary: {
        main: '#666666',
    },
};

type MainComponentProps = {
    authenticated: boolean;
}
const MainComponent = (props: MainComponentProps) => {

    const {authenticated} = props;

    if (!authenticated) {
        return (<PublicRoutes/>);
    }

    return (<PrivateRoutes/>);
};

export default function App() {

    const authenticated = isAuthenticated();
    const [palette, setPalette] = useState(mainPalette);

    useEffect(() => {
        if (!authenticated) {
            setPalette(mainPalette);
            return;
        }

        getCompanyVisualData().then((visual) => {
            setPalette({
                ...mainPalette,
                primary: {
                    main: visual?.primary_color ?? mainPalette.primary.main,
                },
                secondary: {
                    main: visual?.secondary_color ?? mainPalette.secondary.main,
                },
            });
        });
    }, [authenticated]);

    const theme = useMemo(() => (
            createTheme({
                palette: palette,
                typography: {
                    fontFamily: ['"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
                },
            }, ptBR, corePtBr)
        ), [palette],
    );

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                dateAdapter={AdapterDayjs}
                adapterLocale={'pt-br'}
            >
                <Router>
                    <MainComponent authenticated={authenticated}/>
                </Router>
            </LocalizationProvider>
        </ThemeProvider>
    );
}