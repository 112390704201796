import React, {SyntheticEvent, useEffect, useMemo, useRef} from 'react';

import getNestedValue from 'src/components/utils/getNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';
import getOptionLabel from 'src/components/utils/getOptionLabel';
import hasNestedValue from 'src/components/utils/hasNestedValue';

import ApiSelectField from 'src/components/fields/ApiSelect/ApiSelect';
import { CrudApiSelectProps } from 'src/components/crud/fields/CrudApiSelect';
import { TData } from 'src/components/crud/Crud.d';

const CrudApiSelect = <T extends TData>(props: CrudApiSelectProps<T>) => {
    const { column, values, setValues, errors, readOnly = false, required = false } = props;

    const { field, header } = column;

    const { endpoint, labelKey, multiple = false, name } = field;

    const initialRender = useRef(true);
    const apiEndpoint = useMemo(
        () => (typeof endpoint === 'function' ? endpoint(values) : endpoint),
        [endpoint, values]
    );

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        setValues(setNestedValue(values, name, ''));
    }, [apiEndpoint]);

    /**
     * RENDER COMPONENT
     */
    return (
        <ApiSelectField
            label={header}
            endpoint={apiEndpoint}
            queryParams={column.field.queryParams}
            value={getNestedValue(values, name, field.default)}
            onChange={(event: SyntheticEvent, value: string) => {
                setValues(setNestedValue(values, name, value));
            }}
            multiple={multiple}
            getOptionLabel={(option: T) => getOptionLabel(option, labelKey)}
            error={hasNestedValue(errors, name)}
            helperText={getNestedValue(errors, name, '')}
            readOnly={readOnly}
            required={required}
            inputAction={column.field.inputAction}
        />
    );
};
export default CrudApiSelect;
