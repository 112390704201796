import React from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/WarningRounded';

import Center from 'src/components/Center';

import { ErrorProps } from './Error.d';

const Error = (props: ErrorProps) => {
    const { title, subtitle, showBackButton = true } = props;

    const navigate = useNavigate();

    return (
        <Center>
            <WarningIcon color={'error'} fontSize={'large'} />
            <Stack alignItems={'center'}>
                <Typography variant={'h6'} children={title} />
                <Typography variant={'subtitle1'} children={subtitle} />
            </Stack>
            {showBackButton && (
                <Button
                    variant={'contained'}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                    children={'Voltar'}
                />
            )}
        </Center>
    );
};

export default Error;
