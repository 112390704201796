import React from 'react';

import { useQuery } from '@tanstack/react-query';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

import api from 'src/services/api';

import { Page } from 'src/pages/panels/Panels';

import PageList from './PageList';
import { PageSelectorProps } from './PageSelector.d';

const PageSelector = (props: PageSelectorProps) => {
    const { open, onClose, appId, reportId, onSelect } = props;

    /**
     * WORKSPACE LOAD
     */
    const fetchPages = async () => {
        return api.get(`/api/powerbi/apps/${appId}/explorer/reports/${reportId}/pages/`).then((res) => res.data.value);
    };

    const { data: pages, isLoading } = useQuery<void, Error, Page[]>({
        queryKey: ['pages', appId, reportId],
        queryFn: fetchPages,
        enabled: open,
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog onClose={onClose} open={open} fullWidth>
            <CloseIcon
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    cursor: 'pointer',
                    margin: 1,
                }}
            />
            <DialogTitle children={'Selecione uma Página'} />
            <DialogContent>
                {isLoading ? (
                    <Stack
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                            height: '50vh',
                        }}
                        children={<CircularProgress />}
                    />
                ) : (
                    pages && <PageList pages={pages} onSelect={onSelect} />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default PageSelector;
