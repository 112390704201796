import { Columns } from 'src/components/crud/Crud.d';

import { Panel } from 'src/pages/panels/Panel';

import PBIIcon from 'src/assets/img/icons/powerbi.svg';
import LinkIcon from 'src/assets/img/icons/link.svg';
import PdfIcon from 'src/assets/img/icons/pdf.svg';
import EMBIcon from 'src/assets/img/icons/embed.svg';

export const PANEL_TYPES = {
    PBI: 'PBI',
    PDF: 'PDF',
    EXT: 'EXT',
    EMB: 'EMB',
};

export const TYPE_ICON_MAPS = {
    [PANEL_TYPES.PBI]: PBIIcon,
    [PANEL_TYPES.EXT]: LinkIcon,
    [PANEL_TYPES.PDF]: PdfIcon,
    [PANEL_TYPES.EMB]: EMBIcon,
};

export const endpoint = '/api/panels/panels/';

export const columns: Columns<Panel> = [
    {
        accessorKey: 'description',
        header: 'Painel',
    },
    {
        accessorKey: 'group.description',
        header: 'Grupo',
        field: {
            type: 'search',
            name: 'group',
            endpoint: '/api/panels/groups/',
            labelKey: (option: any) => option?.description ?? '',
        },
    },
    {
        accessorKey: 'subgroup.description',
        header: 'Subgrupo',
        getIsReadOnly: (values: any) => !values.group,
        field: {
            type: 'search',
            name: 'subgroup',
            endpoint: (values: any) => values.group ? `/api/panels/groups/${values.group.id}/subgroups/` : '',
            labelKey: (option: any) => option?.description ?? '',
        },
    },
    {
        accessorKey: 'order',
        header: 'Ordem',
        hideColumn: true,
        field: {
            type: 'number',
            default: '1000',
        },
    },
    {
        accessorKey: 'fixed',
        header: 'Fixado',
        field: {
            type: 'check',
            default: false,
        },
    },
    {
        accessorKey: 'new_tab',
        header: 'Abrir em nova aba',
        field: {
            type: 'check',
            default: false,
        },
    },
];

