import React, { Fragment } from 'react';

import CrudText, { CrudTextProps } from 'src/components/crud/fields/CrudText';
import CrudSelect, { CrudSelectProps } from 'src/components/crud/fields/CrudSelect';
import CrudCheckBox, { CrudCheckBoxProps } from 'src/components/crud/fields/CrudCheckBox';
import CrudApiSelect, { CrudApiSelectProps } from 'src/components/crud/fields/CrudApiSelect';
import CrudPassword, { CrudPasswordProps } from 'src/components/crud/fields/CrudPassword';
import CrudHeader, { CrudHeaderProps } from 'src/components/crud/fields/CrudHeader';

import { TData } from 'src/components/crud/Crud.d';
import { CrudFormProps } from 'src/components/crud/CrudForm';

const CrudForm = <T extends TData>(props: CrudFormProps<T>) => {
    const { columns, values, setValues, errors, readOnly = false, size } = props;

    const commonFieldProps = {
        values: values,
        setValues: setValues,
        errors: errors,
        size: size,
    };

    return (
        <Fragment>
            {columns.map((column) => {
                const fieldProps = {
                    key: column['accessorKey'],
                    column: column,
                    readOnly: readOnly || column.readOnly || (column.getIsReadOnly && column.getIsReadOnly(values)),
                    ...commonFieldProps,
                };

                if (!column.enableEditing) {
                    return null;
                }

                switch (column.field?.type) {
                    case 'header':
                        return <CrudHeader {...(fieldProps as CrudHeaderProps<T>)} />;
                    case 'select':
                        return <CrudSelect {...(fieldProps as CrudSelectProps<T>)} />;
                    case 'search':
                        return <CrudApiSelect {...(fieldProps as CrudApiSelectProps<T>)} />;
                    case 'check':
                        return <CrudCheckBox {...(fieldProps as CrudCheckBoxProps<T>)} />;
                    case 'password':
                        return <CrudPassword {...(fieldProps as CrudPasswordProps<T>)} />;
                    case 'custom':
                        return column.field.component(values, setValues, errors, readOnly);
                    default:
                        return <CrudText {...(fieldProps as CrudTextProps<T>)} />;
                }
            })}
        </Fragment>
    );
};

export default CrudForm;
