import { Routes } from 'src/routes/appRoutes/appRoutes.d';

const AppRoutes = {
    // public
    Presentation: { path: '/' },
    DocsPowerBIApp: {path: '/docs/powerbi/apps'},

    // auth
    Login: { path: '/entrar' },
    ResetPassword: { path: '/redefinir-senha/:token' },

    // user
    UserSettings: { path: '/perfil' },

    // dashboard
    Dashboard: { path: '/painel' },

    // panels
    Panel: { path: '/painel/:slug' },

    // admin
    Groups: { path: '/grupo' },
    Subgroups: { path: '/subgrupo' },
    Panels: { path: '/paineis' },
    Users: { path: '/usuarios' },
    Profiles: { path: '/perfis' },

    // powerbi
    Apps: { path: '/powerbi/apps' },
} as Routes;

export default AppRoutes;