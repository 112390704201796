import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import AppRoutes from 'src/routes/appRoutes/appRoutes';

import ResetPasswordPage from '../pages/auth/ResetPassword/ResetPassword';
import RouteWithTenant from './components/RouteWithTenant/RouteWithTenant';
import RouteWithLoader from './components/RouteWithLoader/RouteWithLoader';

import Login from '../pages/auth/Login/Login';

export default function PublicRoutes() {
    return (
        <Routes>

            {/* auth */}
            <Route path={AppRoutes.Login.path}
                   element={<RouteWithTenant><RouteWithLoader component={Login}/></RouteWithTenant>}/>
            <Route path={AppRoutes.ResetPassword.path}
                   element={<RouteWithTenant><ResetPasswordPage/></RouteWithTenant>}/>


            {/* On any route non spedified, return login */}
            <Route path={'*'} element={<Navigate to={AppRoutes.Login.path}/>}/>

        </Routes>
    );
};
