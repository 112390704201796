import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import SearchIcon from '@mui/icons-material/Search';

import { Report, Workspace } from 'src/pages/panels/Panels';

import ReportIcon from 'src/assets/img/icons/report.svg';

import ReportSelector from './ReportSelector';
import { ReportSelectorFieldProps } from './ReportSelectorField.d';

const ReportSelectorField = (props: ReportSelectorFieldProps) => {
    const { value, onChange, onSelect, error, appId } = props;

    const [open, setOpen] = useState(false);

    /**
     * RENDER COMPONENT
     */
    const selectorDisabled = !appId;

    return (
        <>
            <TextField
                type={'text'}
                label={'Id do Painel'}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                helperText={error}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={'start'}>
                            <img src={ReportIcon} alt={'Painel'} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <Tooltip title={'Buscar Painel'}>
                            <span>
                                <IconButton
                                    disabled={selectorDisabled}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                    children={<SearchIcon />}
                                />
                            </span>
                        </Tooltip>
                    ),
                }}
            />

            {!selectorDisabled && (
                <ReportSelector
                    appId={appId}
                    open={open}
                    onSelect={(workspace: Workspace, report: Report) => {
                        onSelect(workspace.id, report.id, report.datasetId);
                        setOpen(false);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default ReportSelectorField;