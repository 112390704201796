import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {getUserData, User} from '../../services/storage/user';

const Dashboard = () => {

    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        getUserData().then(user => {
            setUser(user || {});
        });
    }, []);

    return (<Stack>
        <Typography variant={'h5'}>Olá, {user?.first_name} {user?.last_name}</Typography>
        <Typography variant={'h3'} sx={{mt: 2}}>Bem vindo ao Predict HUB!</Typography>
        <Typography variant={'body1'} sx={{mt: 2}}>
            Aqui você tem acesso rápido à todos os dashboards da sua empresa em único lugar de forma pratica, segura e
            estruturada.
        </Typography>
    </Stack>);
};

export default Dashboard;