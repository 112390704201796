import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';

import api from 'src/services/api';

import { ApiSelectProps } from 'src/components/fields/ApiSelect/ApiSelect.d';

const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'hasAction',
})<{ hasAction: boolean }>(({ hasAction }) => ({
    ...(hasAction
        ? {
              '& .MuiOutlinedInput-root': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
              },
          }
        : {}),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    textTransform: 'lowercase',
}));

const ApiSelect = (props: ApiSelectProps) => {
    const {
        endpoint,
        label,
        placeholder,
        onChange,
        value,
        error,
        helperText,
        multiple,
        getOptionLabel,
        readOnly,
        required,
        inputAction,
        queryParams,
        size = 'medium',
        variant = 'outlined',
    } = props;

    /**
     * FETCH OPTIONS CONTROL
     */
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState<string>('');

    const fetchOptions = async () => {
        return await api
            .get(endpoint, {
                params: {
                    ...queryParams,
                    search: search,
                },
            })
            .then((res) => res.data);
    };

    const disabled = endpoint === '' || readOnly || (value && getOptionLabel(value) === search);

    const { data, isLoading } = useQuery({
        queryKey: ['search', search, endpoint],
        queryFn: fetchOptions,
        enabled: !disabled,
    });

    useEffect(() => {
        setOptions(data?.results ?? []);
    }, [data]);

    /**
     * SEARCH CONTROL
     */
    const [searchText, setSearchText] = useState('');
    const typingTimeoutRef = useRef<any>(null);

    const handleInputChange = (event: SyntheticEvent, newSearchText: string) => {
        setSearchText(newSearchText);
    };

    useEffect(() => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => setSearch(searchText), 750);

        return () => clearTimeout(typingTimeoutRef.current);
    }, [searchText]);

    /**
     * RENDER COMPONENT
     */
    const loading = !disabled && isLoading;

    return (
        <Autocomplete
            size={size}
            multiple={multiple}
            filterOptions={(x) => x}
            openText={'Abrir'}
            closeText={'Fechar'}
            clearText={'Remover'}
            loadingText={'Carregando...'}
            noOptionsText={data?.results ? 'Sem opções para o filtro.' : 'Pesquisar...'}
            loading={loading}
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => getOptionLabel(option)}
            value={value}
            onInputChange={handleInputChange}
            onChange={onChange}
            disabled={readOnly}
            renderInput={(params) => (
                <FormGroup row>
                    <StyledTextField
                        {...params}
                        size={size}
                        label={label}
                        placeholder={placeholder}
                        variant={variant}
                        required={required && (multiple ? value.length === 0 : !value)}
                        error={error}
                        helperText={helperText}
                        hasAction={!!inputAction}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading && <CircularProgress color={'inherit'} size={20} />}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        sx={{
                            flex: 10,
                        }}
                    />

                    {inputAction && (
                        <StyledButton
                            variant={'contained'}
                            disableElevation
                            sx={{
                                backgroundColor: '#444',
                                '&:hover': {
                                    backgroundColor: '#111',
                                },
                            }}
                            onClick={inputAction.onClick}
                            children={inputAction.label}
                        />
                    )}
                </FormGroup>
            )}
        />
    );
};

export default ApiSelect;
