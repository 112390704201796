import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const TenantNotFound = () => {
    return (<main>
        <Box
            height={'100vh'}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pb: 5,
            }}
        >
            <Box>
                <Typography sx={{pb: 2}}>
                    Empresa Inválida =(
                </Typography>

                <Typography variant={'h3'} sx={{pb: 2}}>
                    A empresa solicitada não está ativa.
                </Typography>

                <Typography sx={{pb: 4}}>
                    Oops! Verifique a o seu link de acesso.
                </Typography>
            </Box>
        </Box>
    </main>);
};

export default TenantNotFound;
