import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import { getEmbedToken, refreshEmbedToken } from 'src/services/storage/embed/embed';

import Loader from 'src/components/Loader';
import Error from 'src/components/Error';

import { EmbedDisplayProps } from './EmbedDisplay.d';

const embedUrl = (type: string, id: string) => `https://app.powerbi.com/${type}Embed?${type}Id=${id}`;

const EmbedDisplay = (props: EmbedDisplayProps) => {
    const { panel } = props;

    const [isRetry, setIsRetry] = useState(false);

    const {
        data: token,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['embedToken', panel.app.id, isRetry],
        queryFn: () => {
            if (!isRetry) {
                return getEmbedToken(panel.app.id);
            }

            return refreshEmbedToken(panel.app.id);
        },
    });

    /**
     * RENDER COMPONENT
     */
    if (isLoading) {
        return <Loader text={isRetry ? 'Atualizando autenticação' : 'Autenticando'} />;
    }

    if (isError) {
        return <Error title={'Erro ao carregar dados de autenticação'} subtitle={'Tente novamente mais tarde.'} />;
    }

    return (
        <PowerBIEmbed
            embedConfig={{
                id: panel.report_id,
                type: 'report',
                embedUrl: embedUrl('report', panel.report_id),
                accessToken: token,
                tokenType: models.TokenType.Embed,
                pageName: panel.page_id,
                settings: {
                    panes: {
                        filters: {
                            visible: false,
                        },
                        pageNavigation: {
                            visible: false,
                        },
                    },
                },
            }}
            eventHandlers={
                new Map([
                    [
                        'error',
                        function (event: any) {
                            if (['401', '403'].includes(event.detail.errorCode)) {
                                setIsRetry(true);
                            }
                        },
                    ],
                ])
            }
            cssClassName={'reportClass'}
        />
    );
};

export default EmbedDisplay;
