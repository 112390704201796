import React from 'react';

import { ExternalDisplayProps } from './ExternalDisplay.d';

const ExternalDisplay = (props: ExternalDisplayProps) => {
    const { panel } = props;

    return <iframe title={'ext-panel'} width={'100%'} height={'100%'} allowFullScreen={true} src={panel.path}></iframe>;
};

export default ExternalDisplay;