import React, {useEffect, useState} from 'react';

import {useMutation} from '@tanstack/react-query';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import WarningRounded from '@mui/icons-material/WarningRounded';

import {ConfirmModalProps} from 'src/components/crud/modals/ConfirmModal';
import {TData} from 'src/components/crud/Crud.d';

const ConfirmModal = <T extends TData>(props: ConfirmModalProps<T>) => {
    const {open, entity, title, message, btnText, warningMessage, onSubmit, onCancel, extraActions, children} = props;

    const [modalOpen, setModalOpen] = useState(false);

    /**
     * CONTROL OPEN STATE FROM OUTSIDE
     */
    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    /**
     * EVENT HANDLERS
     */
    const handleSubmit = () => mutateAsync(entity);
    const handleCancel = () => onCancel(entity);

    const {isLoading, mutateAsync} = useMutation<void, Error, T>(
        {
            mutationFn: onSubmit,
        }
    );

    /**
     * DIALOG CONTENT
     */
    const dialogContent = () => {
        return (
            <>
                <DialogContentText children={message}/>
                {!warningMessage ? null : (
                    <Stack spacing={0.5} direction={'row'} alignItems={'end'} justifyContent={'flex-end'}>
                        <WarningRounded color={'error'} fontSize={'small'}/>
                        <Typography color={'error'} variant={'caption'} children={warningMessage}/>
                    </Stack>
                )}
            </>
        );
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={modalOpen} maxWidth={'xs'} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children ? children : dialogContent()}</DialogContent>
            <DialogActions
                sx={{
                    p: '1.25rem',
                    display: 'flex',
                    backgroundColor: '#ECECEC',
                    justifyContent: extraActions ? 'space-between' : 'end',
                }}
            >
                {extraActions ? <span>{extraActions}</span> : null}
                <Stack direction={'row'} spacing={1}>
                    <Button disabled={isLoading} onClick={handleCancel} sx={{color: 'black'}} children={'Cancelar'}/>
                    <Button disabled={isLoading} onClick={handleSubmit} color={'primary'} variant={'contained'}>
                        {isLoading ? <CircularProgress size={20} sx={{mr: 2}}/> : null} {btnText}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;