import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import OpenLinkIcon from '@mui/icons-material/OpenInNew';

import AppRoutes from 'src/routes/appRoutes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import CrudPage from 'src/components/crud/CrudPage';

import TypeSelectorDialog from './TypeSelectorDialog';
import CreateUpdatePanelModal from 'src/pages/panels/Panels/CreateUpdatePanelModal';

import { PanelType } from 'src/pages/panels/Panels/Panels.d';
import { Panel } from 'src/pages/panels/Panel';
import { Columns, RowAction } from 'src/components/crud/Crud.d';

import { TYPE_ICON_MAPS } from './common';

const endpoint = '/api/panels/panels/';

const name = {
    singular: 'Painel',
    plural: 'Painéis',
};

const columns: Columns<Panel> = [
    {
        accessorKey: 'panel_type',
        header: '',
        enableSorting: false,
        enableResizing: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 15,
        grow: false,
        Cell: ({ row }) => {
            const type = row.original.panel_type;

            if (!type || !(type in TYPE_ICON_MAPS)) {
                return <></>;
            }

            return <Box component={'img'} src={TYPE_ICON_MAPS[type]} alt={type} sx={{ width: 20, height: 20 }} />;
        },
    },
    {
        accessorKey: 'description',
        header: 'Painel',
    },
    {
        accessorKey: 'subgroup.description',
        header: 'Subgrupo',
    },
    {
        accessorKey: 'subgroup.group.description',
        header: 'Grupo',
    },
];

const formatData = (data: Record<string, any>) => {
    return {
        ...data,
        results: data.results.map((e: any) => ({
            ...e,
            group: e.subgroup ? e.subgroup.group : null,
        })),
    };
};

const extraRowActions: RowAction<Panel>[] = [
    {
        buildLink: (e) => AppRoutes.Panel.path.replace(':slug', e.id.toString()),
        tooltip: 'Visualizar Painel',
        icon: OpenLinkIcon,
        color: 'primary',
    },
];

const PanelsPage = () => {
    /**
     * MODALS CONTROL
     */
    const [openTypeSelector, setOpenTypeSelector] = useState(false);
    const [openCreateUpdate, setOpenCreateUpdate] = useState(false);
    const [createUpdateType, setCreateUpdateType] = useState<PanelType>('EXT');
    const [entity, setEntity] = useState<Panel | null>(null);

    /**
     * TABLE CONTROL
     */
    const [refresh, setRefresh] = useState(false);
    const doRefresh = () => setRefresh(!refresh);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Cadastros', 'Painéis']} />
                </Box>
            </Stack>

            <CrudPage
                name={name}
                endpoint={endpoint}
                columns={columns}
                extraRowActions={extraRowActions}
                handleCreateUpdate={(entity: Panel | null) => {
                    if (!entity) {
                        setOpenTypeSelector(true);
                        return;
                    }

                    setEntity(entity);
                    setCreateUpdateType(entity.panel_type);
                    setOpenCreateUpdate(true);
                }}
                // API
                formatData={formatData}
                setControlledRefresh={setRefresh}
                controlledRefresh={refresh}
            />

            <TypeSelectorDialog
                open={openTypeSelector}
                onClose={() => setOpenTypeSelector(false)}
                onSelect={(type) => {
                    setOpenTypeSelector(false);
                    setCreateUpdateType(type);
                    setOpenCreateUpdate(true);
                }}
            />

            <CreateUpdatePanelModal
                type={createUpdateType}
                open={openCreateUpdate}
                setOpen={setOpenCreateUpdate}
                entity={entity}
                setEntity={setEntity}
                refetch={doRefresh}
            />
        </>
    );
};

export default PanelsPage;
